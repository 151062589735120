import { createSettingsParams, SettingsParamType } from '@wix/tpa-settings';

export type ISettingsParams = {
  membersAreaTitle: SettingsParamType.Text;
  membersAreaDescription: SettingsParamType.Text;
};

export default createSettingsParams<ISettingsParams>({
  membersAreaTitle: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.my-bookings-widget.header.title'),
  },
  membersAreaDescription: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.my-bookings-widget.header.subtitle'),
  },
});

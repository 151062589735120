import React, { FC } from 'react';
import Text, { TextType } from '../../Components/Text';
import { classes } from './Header.st.css';
import { useSettings } from '@wix/tpa-settings/react';
import { getContent } from '../../../../utils/content/content';
import settingsParams from '../../settingsParams';
import { HeaderDataHooks } from '../datahooks';

export const Header: FC = () => {
  const settings = useSettings();

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Text
          data-hook={HeaderDataHooks.PageTitle}
          type={TextType.Title}
          tagName="h2"
        >
          {getContent({
            settings,
            settingsParam: settingsParams.membersAreaTitle,
          })}
        </Text>
      </div>
      <Text
        data-hook={HeaderDataHooks.PageSubtitle}
        type={TextType.Subtitle}
        tagName="p"
      >
        {getContent({
          settings,
          settingsParam: settingsParams.membersAreaDescription,
        })}
      </Text>
    </div>
  );
};

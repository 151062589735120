import React, { FC } from 'react';
import { st, classes } from './App.st.css';
import { WidgetDataHooks } from '../datahooks';
import { Header } from '../Header';

const App: FC = () => {
  return (
    <div
      className={st(classes.root, {})}
      data-hook={WidgetDataHooks.MyBookingsMainWidget}
    >
      <Header />
    </div>
  );
};

export default App;
